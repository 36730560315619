import { Controller } from 'stimulus';
import { Printd } from 'printd';
import commonStyle from '../../styles/print/common.css?raw';
import jobticketStyle from '../../styles/print/jobticket.css?raw';
import '../../styles/print/common.css';
import '../../styles/print/jobticket.css';

export default class extends Controller {
  print(event) {
    event.preventDefault();
    const d = new Printd({
      parent: document.getElementById('printable'),
    });
    d.print(document.getElementById('printable'), [
      commonStyle,
      jobticketStyle,
    ]);
  }
}
