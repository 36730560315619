<template>
  <div class="camera-player">
    <video ref="player" autoplay playsinline muted></video>
    <button class="btn btn-primary" @click.prevent="onTake">
      <i class="fas fa-camera"></i>
      <span class="ml-1">写真を撮る</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'CameraPlayer',
  props: {
    facingMode: {
      type: String,
      default: 'environment',
    },
  },
  data() {
    return {
      videoTracks: undefined,
      error: '',
      isPreview: false,
    };
  },
  async mounted() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: this.facingMode },
      });
      this.$refs.player.srcObject = stream;
      this.videoTracks = stream.getVideoTracks();
    } catch (error) {
      this.error =
        'お使いのPC、スマートフォンではカメラが存在しない、もしくは使用できません。';
    }
  },
  destroyed() {
    this.videoTracks.forEach((track) => track.stop());
  },
  methods: {
    onTake() {
      this.$emit('take', this.$refs.player);
    },
  },
};
</script>

<style lang="scss" scoped>
.camera-player {
  video {
    display: block;
    margin: auto auto 1rem;
    max-width: 100%;
    height: auto;
  }
}
</style>
