import { config, library, dom } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { faLine } from '@fortawesome/free-brands-svg-icons';

config.mutateApproach = 'sync';

library.add(fas, far, faLine);

dom.watch();
