import { Controller } from 'stimulus';
import { Printd } from 'printd';
import commonStyle from '../../styles/print/common.css?raw';
import reportStyle from '../../styles/print/report.css?raw';
import '../../styles/print/common.css';
import '../../styles/print/report.css';

export default class extends Controller {
  static targets = ['printable'];

  connect() {
    if (this.hasPrintableTarget) {
      this.print();
    }
  }

  print() {
    const d = new Printd({
      parent: this.printableTarget,
    });
    d.print(this.printableTarget, [commonStyle, reportStyle]);
  }
}
